import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable, switchMap } from 'rxjs';
import { shareReplay, take, tap } from 'rxjs/operators';
import { getLogger } from 'tofe-core';
import { LanguageConfigurationService } from 'tofe-language';
import { TofeCustomization } from 'top-api-sdk-angular';

import { DefaultCustomization } from './customization.constants';
import { CustomizationLoaderService } from './customization-loader.service';

const logger = getLogger('[Service][Customization]');

@Injectable({
  providedIn: 'root',
})
export class CustomizationService {
  readonly current$: Observable<TofeCustomization>;
  private readonly currentSubject = new BehaviorSubject<string>('default');
  private current: TofeCustomization = DefaultCustomization;

  constructor(
    private readonly conf: LanguageConfigurationService,
    customizationLoader: CustomizationLoaderService,
  ) {
    this.current$ = this.currentSubject.asObservable().pipe(
      filter((x) => !!x),
      tap((y) => {
        logger.debug(`using ${y}`);
      }),
      switchMap((name) => customizationLoader.getCustomization(name)),
      tap({
        next: (y) => {
          logger.debug(`current$`, y);
          this.initCurrent(y);
        },
        error: (err) => {
          logger.error(`current$ error`, err);
        },
      }),
      shareReplay(1),
    );
  }

  setCurrent(name?: string): Observable<TofeCustomization> {
    logger.debug(`setCurrent`, name);
    this.currentSubject.next(name || 'default');
    return this.current$.pipe(take(1));
  }

  getCurrent(): TofeCustomization {
    return this.current;
  }

  initCssVariables(nativeElement: HTMLElement) {
    const style = nativeElement.style;
    const curr = this.getCurrent();
    const cssVariables = (curr.cssVariables as Record<string, string>) || {};
    Object.keys(cssVariables).forEach((key) => {
      style.setProperty(key, cssVariables[key]);
    });
  }

  private initCurrent(current: TofeCustomization) {
    this.current = current;
    const language = current.language;
    if (language) {
      this.conf.setCustomization(language);
    }
  }
}
