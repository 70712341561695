import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { getLogger } from 'tofe-core';
import { TofeCustomization } from 'top-api-sdk-angular';
import { merge } from 'ts-deepmerge';

import { DefaultCustomization } from './customization.constants';

const logger = getLogger('[Service][CustomizationLoader]');

@Injectable({
  providedIn: 'root',
})
export class CustomizationLoaderService {
  constructor(private readonly http: HttpClient) {}

  getCustomization(name: string): Observable<TofeCustomization> {
    if (name === 'default') return of(DefaultCustomization);
    return this.http.get<TofeCustomization>(`assets/customizations/${name}.json`).pipe(
      map((x) => merge(DefaultCustomization, x)),
      catchError((err) => {
        logger.error(`current$ http error`, err);
        return of(DefaultCustomization);
      }),
    );
  }
}
