import { Injectable } from '@angular/core';
import { HttpRequestState } from 'ngx-http-request-state';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { getLogger } from 'tofe-core';
import { CopTOFEConfiguration, TaskInfo } from 'top-api-sdk-angular';

import { SessionInfo } from './types';

const debug = getLogger('[Service][TopSession]').debug;

export type SessionInfoRequestState = HttpRequestState<SessionInfo>;

@Injectable()
export class TopSessionService {
  readonly session$: Observable<SessionInfoRequestState>;
  private readonly sessionSubject = new BehaviorSubject<Observable<SessionInfoRequestState>>(
    of({ isLoading: true, error: undefined }),
  );

  private sessionSnapshot?: Readonly<SessionInfo>;

  constructor() {
    this.session$ = this.sessionSubject.asObservable().pipe(
      switchMap((x) => x),
      tap((session) => {
        this.sessionSnapshot = Object.freeze(session.value);
        debug('session$', session);
      }),
      shareReplay(1),
    );
  }

  setSession(session$: Observable<SessionInfoRequestState>) {
    this.sessionSubject.next(session$);
  }

  hasSession(): boolean {
    return !!this.sessionSnapshot;
  }

  getSession(): Readonly<SessionInfo> {
    if (!this.sessionSnapshot) {
      throw new Error('sessionSnapshot not found');
    }
    return this.sessionSnapshot;
  }

  getTofeConfiguration(): Readonly<CopTOFEConfiguration> {
    const ret = this.getSession()?.processData?.tofeConfiguration ?? {};
    return Object.freeze(ret);
  }

  /**
   * This method is an easier way to get taskId from session
   * we prefer it instead of using session$ to reduce rxjs complexity
   */
  getTaskId(): number {
    const ret = Number(this.getSession()?.startData?.taskId || 0);
    if (!ret) {
      throw new Error('taskId not found');
    }
    return ret;
  }

  /**
   * This method is an easier way to get sessionId from session
   */
  getSessionId(): string {
    const ret = this.getSession()?.id;
    if (!ret) {
      throw new Error('sessionId not found');
    }
    return ret;
  }

  getTaskInfo(taskName: string): TaskInfo | undefined {
    return this.getTasks().find((x) => x.name === taskName);
  }

  getTasks(): TaskInfo[] {
    return this.getSession().processData?.tasks || [];
  }
}
