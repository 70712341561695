import { inject, Injectable } from '@angular/core';
import { of } from 'rxjs';
import { getLogger } from 'tofe-core';
import { ProcessService, StartResponse } from 'top-api-sdk-angular';

import { TopSessionService } from './top-session.service';
import { SessionInfo } from './types';

const debug = getLogger('[Service][ProcessInResponse]').debug;

@Injectable()
export class ProcessInResponseService {
  private readonly topSession = inject(TopSessionService);
  private readonly api = inject(ProcessService);

  setStartResponse(startResponse: StartResponse) {
    if (!this.topSession.hasSession()) {
      return;
    }
    const prev = this.topSession.getSession();
    this.topSession.setSession(
      of({
        isLoading: false,
        value: this.handleSessionFromLastResponse(prev, startResponse),
        error: undefined,
      }),
    );
  }

  /**
   * This method is used to update session data based on last response
   * when a task is completed we need to update CopApiKey with new a new sessionKey
   * and also the sessionData is updated with new startData
   */
  private handleSessionFromLastResponse(process: SessionInfo, startData: StartResponse) {
    // this is interesting
    // we change credentials base on process available on each response
    this.api.configuration.credentials['CopApiKey'] = 'COP ' + startData.sessionKey;
    debug('handleSessionFromLastResponse', startData);
    return {
      ...process,
      // here we update sessionData with new startData
      startData,
    };
  }
}
